ion-content {
    --background:#f0f0f0;
}
ion-modal .modal-wrapper ion-content{
    --background:#fff !important;
}
.whiteBar {
    background: #fff;
}
.contentArea {
    max-width: 600px;
    margin: 0 auto;
}
.contentHolder {
    max-width: 780px;
}
.headingText {
    font-weight: 700;
}

.submit-enter {
    position: absolute;
    visibility: hidden;
    left: -1000px;
}
.iconHolder {
    width: 100%;
    margin: 15px auto;
}
.iconHolder i.wi {
    text-align: center;
    margin-top: 55px;
    font-size: 110px;
    color: #004e82;
}
span.temp {
    font-size: 60px;
    font-weight: 700;
    color: #004e82;
    top: -24px;
    position: relative;
    left: 5px;
}
.annoucement {
    font-weight: 700;
}
.fdLogo {
    max-width: 250px;
}
.widgets {
    background-color: #004e82;
}
.bigIcon {
    font-size: 100px;
}
.headerIcon {
    height: 25px;
}